import React from 'react';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const Services = ({ t }) => {
  const { i18n } = useTranslation();

  const services = [
    {
      icon: 'fa fa-home',
      titleKey: 'services.propertyManagement.title',
      descriptionKey: 'services.propertyManagement.description'
    },
    {
      icon: 'fa fa-camera',
      titleKey: 'services.photography.title',
      descriptionKey: 'services.photography.description'
    },
    {
      icon: 'fa fa-line-chart',
      titleKey: 'services.revenue.title',
      descriptionKey: 'services.revenue.description'
    },
    {
      icon: 'fa fa-wrench',
      titleKey: 'services.engineering.title',
      descriptionKey: 'services.engineering.description'
    }
  ];

  return (
    <div id="services" className="text-center" dir={i18n.dir()}>
      <div className="container">
        <div className="section-title">
          <h2>{t('services.mainTitle')}</h2>
          <p>{t('services.subtitle')}</p>
        </div>
        <div className={i18n.dir() === "rtl" ? "row flex-row-reverse" : "row"}>
          {services.map((service, index) => (
            <div key={index} className="col-md-3">
              <div className="service-item">
                <i className={service.icon}></i>
                <h3>{t(service.titleKey)}</h3>
                <p>{t(service.descriptionKey)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Services);
