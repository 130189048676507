import React from 'react';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const About = ({ t }) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const stats = [
    { number: '250+', key: 'properties' },
    { number: '95%', key: 'satisfaction' },
    { number: '24/7', key: 'support' },
    { number: '5+', key: 'experience' }
  ];

  const keyPoints1 = [
    t('about.keyPoints.management'),
    t('about.keyPoints.support'),
    t('about.keyPoints.analysis'),
    t('about.keyPoints.quality')
  ];

  const keyPoints2 = [
    t('about.keyPoints.reporting'),
    t('about.keyPoints.maintenance'),
    t('about.keyPoints.marketing'),
    t('about.keyPoints.screening')
  ];

  return (
    <div id="about" dir={i18n.dir()}>
      <div className="container">
        <div className="section-title">
          <h2>{t('about.title')}</h2>
        </div>
        <div className={`row ${isRTL ? 'flex-row-reverse' : ''}`}>
          <div className="col-xs-12">
            <div className={`about-text text-${isRTL ? 'right' : 'left'}`}>
              <h3 className="text-center">{t('about.subtitle')}</h3>
              <p className={`text-${isRTL ? 'right' : 'left'}`}>{t('about.description')}</p>
              <div className="list-style">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul className={isRTL ? 'rtl-list' : ''}>
                      {keyPoints1.map((point, index) => (
                        <li key={index}>
                          <i className="fa fa-check"></i> {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul className={isRTL ? 'rtl-list' : ''}>
                      {keyPoints2.map((point, index) => (
                        <li key={index}>
                          <i className="fa fa-check"></i> {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row stats-row">
          {stats.map((stat, index) => (
            <div key={index} className="col-md-3 col-sm-6">
              <div className="stat-box">
                <div className="stat-number">{stat.number}</div>
                <div className="stat-label">{t(`about.stats.${stat.key}`)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(About);
