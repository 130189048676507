import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class Header extends Component {
  render() {
    const { t } = this.props;
    
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>{t('header.title')}</h1>
                <p className="subtitle">{t('header.subtitle')}</p>
                <p className="description">{t('header.description')}</p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  {t('header.learnMore')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withTranslation()(Header);
