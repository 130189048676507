import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

export class Contact extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-12">
              <div dir={i18n.dir()} className="row">
                <div className="section-title">
                  <h2>  {t('Contact.getInTouch')}</h2>
                  <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-envelope-o"></i> {t('Email')}
                      </span>{" "}
                      {t('Contact.email')}
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">

            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={t('Contact.whatsapp')}>
                        <i className="fa fa-whatsapp"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href={t('Contact.instagram')}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li> */}
                    {/* <li>
                      <a href={t('Contact.twitter')}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2024 Sakn Alshrq
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Contact);
