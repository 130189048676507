import React, { Component } from 'react';
import Navigation from './components/navigation';
import Header from './components/header';
import Contact from './components/contact';
import About from './components/about';
import Services from './components/services';
import Canvas from './components/Canvas.tsx';
import './App.css';

class App extends Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    // Add loading state
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);

    // Add scroll event listener for smooth scrolling
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const target = document.querySelector(this.getAttribute('href'));
        if (target) {
          target.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    });
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <div className="app-container">
        <Navigation />
        <Header />
        <About />
        <Canvas />
        <Services />
        <Contact />
      </div>
    );
  }
}

export default App;
