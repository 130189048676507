import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

export class Navigation extends Component {
  state = {
    isOpen: false
  };

  toggleNav = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { t } = this.props;
    const { isOpen } = this.state;
    const isRTL = i18n.dir() === 'rtl';

    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <div className="logo-container">
              <a className="navbar-brand" href="#page-top">
                <img src="/img/logo_long.png" alt="Verdure" />
              </a>
            </div>

            <div className="desktop-nav">
              <ul className="nav navbar-nav">
                <li>
                  <a href="#about" className="page-scroll">
                    {t('Navigation.about')}
                  </a>
                </li>
                <li>
                  <a href="#services" className="page-scroll">
                    {t('Navigation.services')}
                  </a>
                </li>
                <li>
                  <a href="#contact" className="page-scroll">
                    {t('Navigation.contact')}
                  </a>
                </li>
                <li className="language-toggle">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    isRTL ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
                  }}>
                    {isRTL ? 'English' : 'العربية'}
                  </a>
                </li>
              </ul>
            </div>

            <button
              type="button"
              className={`navbar-toggle ${isOpen ? 'active' : ''}`}
              onClick={this.toggleNav}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>

          <div className={`navbar-collapse ${isOpen ? 'show' : ''}`}>
            <ul className="nav navbar-nav mobile-nav">
              <li>
                <a href="#about" className="page-scroll" onClick={this.toggleNav}>
                  {t('Navigation.about')}
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll" onClick={this.toggleNav}>
                  {t('Navigation.services')}
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll" onClick={this.toggleNav}>
                  {t('Navigation.contact')}
                </a>
              </li>
              <li className="language-toggle">
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  isRTL ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
                  this.toggleNav();
                }}>
                  {isRTL ? 'English' : 'العربية'}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(Navigation);
